/*! MAIN.JS */
@@include("./components/email-signup.js")
@@include("./components/menu-toggle.js")
@@include("./components/share-toggle.js")
@@include("./components/sticky-header.js")
@@include("./components/set-height.js")
@@include("./components/image-loader.js")
@@include("./components/photo-credits.js")

$('document').ready(function(){
  emailSignup();
  menuToggle();
  shareToggle();
  stickyHeader();
  setHeight();
  loadImages();
  photoCredits();
});

var windowWidth = window.innerWidth;
var windowHeight = window.innerHeight;
$(window).resize($.debounce(100,false,function(){
  // exclude browser address bar resize
  if(windowWidth != window.innerWidth || Math.abs(windowHeight - window.innerHeight) > 95) {
    setHeight();
    windowWidth = window.innerWidth;
    windowHeight = window.innerHeight;
  }
}));
